import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Scroller from "../components/scroller"

import Solutions from "../images/solutions.png"

import TransportQuebec from "../images/transport-quebec.png"
import VilleDeMontreal from "../images/ville-de-montreal.png"
import VilleDeQuebec from "../images/ville-de-quebec.png"
import DolbeauMatassini from "../images/dolbeau-matassini.png"
import Victoriaville from "../images/victoriaville.png"
import UniversiteSherbrooke from "../images/universite-sherbrooke.png"

import AncienneLorette from "../images/ancienne-lorette.png"
import SaintLambert from "../images/saint-lambert.png"
import PontsJacquesCartierChamplain from "../images/ponts-jacques-cartier-champlain.png"
import ParcJeanDrapeau from "../images/parc-jean-drapeau.png"
import VilleSherbrooke from "../images/ville-sherbrooke.png"
import VilleLevis from "../images/ville-levis.png"

import BonnesPratiques from "../images/IconeBonnesPratiques.svg"
import SuiviMeteo from "../images/IconeSuiviMeteo.svg"
import GestionRessources from "../images/IconeGestionRessources.svg"
import CamionGratte from "../images/CamionGratte.svg"
import Urbain3 from "../images/Urbain3.svg"
import Igloo from "../images/Igloo.svg"
import ObjetsConnectesMeteo from "../images/objets-meteo-connectes.svg"
import Epandage from "../images/epandage.svg"

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  handlePortfolioClick(index, e) {
    e.preventDefault();
    this.setModal(true, index);
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current
    });
  }

  responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  render() {
    return (
      <Layout>
        <Seo title="Accueil" />

        <header className="mission" id="mission">
          <div className="container h-100">
            <div className="row text-white text-center mb-5">
              <div className="col-12">
                <h1>Aider les responsables de réseaux routiers<br />
                  <span>à optimiser l'entretien hivernal</span></h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 text-center">
                <div className="card border-light p-5">
                  <div className="background"></div>
                  <img src={BonnesPratiques} alt="Bonne Pratiques" className="img-fluid" />
                  <p className="mb-2 mt-4">Formation du personnel</p>
                </div>
              </div>
              <div className="col-md-4 text-center">
                <div className="card border-light p-5">
                  <div className="background"></div>
                  <img src={SuiviMeteo} alt="Nuage" className="img-fluid" />
                  <p className="mb-2 mt-4">Outil de suivi des opérations</p>
                </div>
              </div>
              <div className="col-md-4 text-center">
                <div className="card border-light p-5">
                  <div className="background"></div>
                  <img src={GestionRessources} alt="Thermomètre" className="img-fluid" />
                  <p className="mb-2 mt-4">Outils de gestion des ressources</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mb-5">
              <div className="col-12 text-center">
                <p className="mb-4 text-white">Vision Météo offre des services d'expertise conseil en viabilité hivernale depuis 2003. Les formations s'adressent au personnel opérationnel, technique ou encadrant. L'entreprise développe divers produits sous la forme d'application Web pour permettre aux décideurs et aux gestionnaires d'optimiser l'utilisation des ressources.</p>
                <a className="btn btn-secondary btn-xl mt-2" href="mailto:emorin@visionmeteo.com">Contactez-nous</a>
              </div>
            </div>
          </div>
        </header>

        <section className="page-section" id="expertise">
          <div className="bg-top"></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 offset-lg-1 d-sm-block d-md-block d-lg-none">
                <img src={CamionGratte} alt="Solutions" className="img-fluid" />
              </div>
              <div className="col-lg-5 text-left text-white">
                <h2><span>Formation</span> du personnel opérationnel et technique</h2>
                <p>Nous sensibilisons le personnel opérationnel aux bonnes pratiques des épandages de sel et d'abrasifs.</p>
                <p>Nous formons les contremaîtres et le personnel technique à anticiper les situations météo-routières et à appliquer une gestion de risques en utilisant les données que nous avons collectées les hivers précédents.</p>
              </div>
              <div className="col-lg-5 offset-lg-1 d-none d-sm-none d-md-none d-lg-block">
                <img src={CamionGratte} alt="Ville neige" className="img-fluid" />
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-lg-5">
                <img src={Urbain3} alt="Solutions" className="img-fluid" />
              </div>
              <div className="col-lg-5 offset-lg-1 text-left text-white">
                <h2><span>Application web</span> pour les décisions et la mobilisation</h2>
                <p>Nous procurons des applications Web standard ou personnalisées pour être en mesure d'effectuer une gestion des risques météoroutiers.</p>
                <p>Les indicateurs que nous élaborons permettent de réaliser des interventions au bon moment, au bon endroit et à la bonne intensité.</p>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-lg-5 offset-lg-1 d-sm-block d-md-block d-lg-none">
                <img src={Igloo} alt="Solutions" className="img-fluid" />
              </div>
              <div className="col-lg-5 text-left text-white">
                <h2><span>Application Web</span> pour la gestion des ressources</h2>
                <p>Nous procurons aux gestionnaires des tableaux de bord entièrement configurables pour une visualisation rapide et efficace des consommations de sel et d'abrasifs.</p>
                <p>Nos indicateurs permettent de comparer les événements et les hivers pour une réédition de compte juste et précise.</p>
              </div>
              <div className="col-lg-5 offset-lg-1 d-none d-sm-none d-md-none d-lg-block">
                <img src={Igloo} alt="Solutions" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="bg-bottom"></div>
        </section>

        <section className="page-section" id="application">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 text-left text-white">
                <h2>Tirer le maximum de la donnée grâce au<br /><span>data science</span></h2>
                <p>Une information n'est utile que si elle est facile d'accès et simple à interpréter.</p>
                <p>Vision Météo utilise les techniques les plus récentes de traitement de données :</p>
                <ul className="mb-5">
                  <li>- pour agréger les données volumineuses et en tirer des indicateurs</li>
                  <li>- pour les intégrer dans des supports visuels intuitifs</li>
                  <li>- pour faire des liens en temps réel avec d'autres sources de données.</li>
                </ul>
                <h3>Suivez les événements météo-routiers en<br /><span> temps réel</span></h3>
                <p>Nous offrons l'accès à un premier niveau d'information météoroutière à tout intervenant en viabilité hivernale.</p>
                <p>Les données sont mises à jour en continu.</p>
                <p>Partagez cette application Web avec vos collaborateurs et vos amis.</p>
                <Link to="/meteo" className="btn btn-secondary btn-xl mt-2 mb-5">Consulter l'application web gratuite</Link>
              </div>
              <div className="col-lg-5">
                <img src={Solutions} alt="Solutions" />
              </div>
            </div>
          </div>
        </section>

        <section className="page-section mb-5" id="donnees">
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-12 text-center">
                <h2 className="mt-0">Se mesurer, <span>c'est s'améliorer!</span></h2>
              </div>
            </div>
            <div className="row mb-5 justify-content-center">
              <div className="col-md-5 text-center">
                <div className="card border-light p-5">
                  <div className="background"></div>
                  <img src={ObjetsConnectesMeteo} alt="Bonne Pratiques" className="img-fluid" />
                  <p className="mb-2 mt-4">Objects connectés<br />météo</p>
                </div>
                <h5><span>Tenir compte des micro-climats</span></h5>
                <p>Nous assemblons des unités de collecte de données météorologiques et routières.</p>
                <p>Ces mini-stations météo permettent d'être réparties sur plusieurs points en minimisant les coûts.</p>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5 text-center">
                <div className="card border-light p-5">
                  <div className="background"></div>
                  <img src={Epandage} alt="Bonne Pratiques" className="img-fluid" />
                  <p className="mb-2 mt-4">Précision des systèmes d'épendage</p>
                </div>
                <h5><span>Avoir des données fiables</span></h5>
                <p>Nous avons mis au point une méthode pour étalonner les différentes composantes des systèmes d'épandage.</p>
                <p>Celle-ci est conforme aux pratiques recommandées par le Ministère des Transports.</p>
              </div>
            </div>
          </div>
        </section>



        <section className="page-section pt-3 pb-5" id="medias">
          <div className="bg-container"></div>
          <div className="container">
            <div className="row text-white mb-2">
              <div className="col-12">
                <h2>Section<span> Média</span></h2>
                <p>Nous contribuons à améliorer les connaissances dans le domaine du déneigement des rues, des routes, des autoroutes, des trottoirs et des pistes cyclables.</p>
                <p>Nous nous tenons à la disposition des médias pour fournir des informations techniques à propos :</p>
                <ul>
                  <li>- des impacts de la météo sur les conditions de circulation ;</li>
                  <li>- des difficultés de gérer les risques de mauvaises conditions routières ;</li>
                  <li>- des bonnes pratiques pour maintenir la sécurité et la fluidité des transports routiers.</li>
                </ul>
              </div>
            </div>
            <div className="row text-white mb-5">
              <div className="col-12">
                <p>Ci-dessous des exemples de notre contribution pour la presse écrite, radio ou télé :</p>
                <ul>
                  <li><a target="_blank" href="https://ici.radio-canada.ca/ohdio/premiere/emissions/Par-ici-l-info/segments/entrevue/105634/temperature-meteo-changements-climatique-conduite-automobile-vision-meteo">Radio Canada - L'art d'adapter sa conduite aux changements climatiques</a></li>
                  <li><a target="_blank" href="https://www.ledevoir.com/societe/transports-urbanisme/547566/les-changements-climatiques-bouleversent-la-conduite-automobile">Le Devoir - Les changements climatiques boulversent la conduite automobile</a></li>
                  <li><a target="_blank" href="https://www.rcinet.ca/fr/2019/02/11/conduite-automobile-hiver-changements-climatiques/">Radio Canada - Adapter sa conduite aux rigueurs de l’hiver, une tâche de plus en plus hasardeuse</a></li>
                  <li><a target="_blank" href="https://www.tvanouvelles.ca/2019/06/18/notre-dependance-au-sel-detruit-nos-routes">TVA - Notre dépendance au sel détruit nos routes</a></li>
                  <li><a target="_blank" href="https://ici.radio-canada.ca/nouvelle/1152107/prevenir-accidents-conditions-meteorologiques-extremes">Radio Canada - Prévenir des accidents dans des conditions météorologiques extrêmes</a></li>
                  <li><a target="_blank" href="https://www.journaldemontreal.com/2019/12/21/les-prix-du-deneigement-explosent-partout-au-quebec">Le Journal de Montréal - Les prix du déneigement explosent partout au Québec</a></li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="partners">
          <div className="container text-center">
            <div className="row justify-content-center pt-5 pb-3">
              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={TransportQuebec} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={VilleDeMontreal} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={VilleDeQuebec} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={DolbeauMatassini} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={Victoriaville} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={UniversiteSherbrooke} className="img-fluid" />
                </a>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={AncienneLorette} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={SaintLambert} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={PontsJacquesCartierChamplain} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={ParcJeanDrapeau} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={VilleSherbrooke} className="img-fluid" />
                </a>
              </div>

              <div className="col-6 col-md-2">
                <a className="" href="https://startbootstrap.com/themes/creative/">
                  <img src={VilleLevis} className="img-fluid" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="text-white mb-5" id="newsletter">
          <div className="container pt-5 pb-5">
            <div className="row justify-content-center">
              <div className="col-lg-6 text-center">
                <h3 className="mb-4">Obtenez de la formation<br /> personnalisée pour vos équipes</h3>
                <p className="mb-5">En vous inscrivant à l’infolettre, obtenez des ressources et des formations pour vos équipes.</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-7 col-lg-4 text-center mb-5 mb-lg-0">
                <div className="input-group mb-3">
                  <input type="text" className="form-control pr-5" placeholder="Courriel" aria-label="Email" aria-describedby="basic-addon1" />
                  <a className="btn btn-primary btn-xl">Envoyer</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}


export const imageData = graphql`
  query {
    images: allFile(filter: {relativePath: {glob: "portfolio/fullsize/*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
